import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

if (process.env.CLIENT) {
  require('./SearchBarResults.scss'); // eslint-disable-line global-require
}

export const HighlightSearchTerm = ({ searchTerm, title }) => {
  const lowerCaseTitle = title.toLowerCase();
  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  const capitalisedTitle = title.substr(0, 1).toUpperCase() + title.substr(1);

  if (!lowerCaseTitle.includes(lowerCaseSearchTerm)) return capitalisedTitle;

  const start = capitalisedTitle.substr(0, lowerCaseTitle.indexOf(lowerCaseSearchTerm));
  const middle = capitalisedTitle.substr(
    lowerCaseTitle.indexOf(lowerCaseSearchTerm),
    lowerCaseSearchTerm.length
  );
  const end = capitalisedTitle.substr(
    lowerCaseTitle.indexOf(lowerCaseSearchTerm) + lowerCaseSearchTerm.length
  );

  return (
    <>
      {start}
      <b>{middle}</b>
      {end}
    </>
  );
};

HighlightSearchTerm.displayName = 'HighlightSearchTerm';

HighlightSearchTerm.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const SearchBarResults = ({
  searchResults,
  searchTerm,
  selectedSearchResultIndex,
  selectSearchResult,
  clearSelectedSearchResult,
  clickSearchResult,
  clearSearchResults,
}) => (
  <div className="searchbar__results">
    <div className="gel-wrap">
      {searchResults.map(({ id, title }, index) => (
        <div
          onClick={() => clickSearchResult(title, searchTerm)}
          onMouseEnter={() => selectSearchResult(index)}
          onMouseLeave={clearSelectedSearchResult}
          onKeyDown={event => {
            if (event.key === 'Enter') clickSearchResult(title, searchTerm);
            else if (event.key === 'Escape') clearSearchResults();
          }}
          className={classNames('gel-pica', 'searchbar__results__result', {
            selected: index === selectedSearchResultIndex,
          })}
          key={id}
          role="textbox"
          tabIndex="0"
          id="search-suggestion"
          onBlur={event => {
            if (event.relatedTarget.id !== 'search-suggestion') clearSearchResults();
          }}
        >
          <HighlightSearchTerm title={title} searchTerm={searchTerm} />
        </div>
      ))}
    </div>
  </div>
);

SearchBarResults.propTypes = {
  searchResults: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, title: PropTypes.string })
  ).isRequired,
  searchTerm: PropTypes.string.isRequired,
  selectedSearchResultIndex: PropTypes.number,
  selectSearchResult: PropTypes.func.isRequired,
  clearSelectedSearchResult: PropTypes.func.isRequired,
  clickSearchResult: PropTypes.func.isRequired,
  clearSearchResults: PropTypes.func.isRequired,
};

SearchBarResults.defaultProps = {
  selectedSearchResultIndex: undefined,
};

export default SearchBarResults;
