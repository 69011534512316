/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import SearchBarInput from './SearchBarInput';
import SearchBarResults from './SearchBarResults';
import {
  clearSearchTerm as clearSearchTermAction,
  selectSearchResult as selectSearchResultAction,
  clearSearchResults as clearSearchResultsAction,
  clearSelectedSearchResult as clearSelectedSearchResultAction,
  clickSearchResult as clickSearchResultAction,
  getSuggestedSearchResults as getSuggestedSearchResultsAction,
  updateSearchTerm as updateSearchTermAction,
  updateDisplaySearchTerm as updateDisplaySearchTermAction,
} from './actions';

if (process.env.CLIENT) {
  require('./SearchBar.scss'); // eslint-disable-line global-require
}

export class SearchBar extends Component {
  searchForm = React.createRef();

  searchBar = React.createRef();

  componentDidUpdate() {
    if (this.props.shouldSubmit) {
      this.searchForm.current.submit();
    }
  }

  submitForm = event => {
    event.preventDefault();
    const { searchResults, clickSearchResult, selectedSearchResultIndex } = this.props;
    if (selectedSearchResultIndex === undefined) {
      return this.searchForm.current.submit();
    }

    const selectedSearchResult = get(searchResults, [selectedSearchResultIndex, 'title']);
    return clickSearchResult(selectedSearchResult);
  };

  handleKeyDown = event => {
    const {
      updateDisplaySearchTerm,
      selectSearchResult,
      selectedSearchResultIndex,
      clearSelectedSearchResult,
      clearSearchTerm,
      searchResults,
      searchTerm,
    } = this.props;
    switch (event.key) {
      case 'ArrowDown': {
        event.preventDefault();
        const index =
          selectedSearchResultIndex === undefined ||
          selectedSearchResultIndex === searchResults.length - 1
            ? 0
            : selectedSearchResultIndex + 1;
        const finalIndex = index % searchResults.length;
        const displaySearchTerm = searchResults[finalIndex].title;
        selectSearchResult(finalIndex);
        updateDisplaySearchTerm(displaySearchTerm);
        return;
      }
      case 'ArrowUp': {
        event.preventDefault();
        const index =
          selectedSearchResultIndex === undefined || selectedSearchResultIndex === 0
            ? searchResults.length - 1
            : selectedSearchResultIndex - 1;
        const displaySearchTerm = searchResults[index].title;
        selectSearchResult(index);
        updateDisplaySearchTerm(displaySearchTerm);
        return;
      }
      case 'Escape': {
        if (selectedSearchResultIndex === undefined) {
          clearSearchTerm();
          return;
        }
        clearSelectedSearchResult();
        updateDisplaySearchTerm(searchTerm);
        return;
      }
      default: {
        if (event.key !== 'Enter') clearSelectedSearchResult();
      }
    }
  };

  handleClickOutside = () => {
    const { clearSearchResults } = this.props;

    if (getComputedStyle(this.searchBar.current, null).display === 'block') {
      clearSearchResults();
    }
  };

  render() {
    const {
      customGetSuggested,
      onSubmit,
      searchResults,
      searchTerm,
      displaySearchTerm,
      getSuggestedSearchResults,
      selectedSearchResultIndex,
      updateSearchTerm,
      clearSearchTerm,
      selectSearchResult,
      clearSearchResults,
      clearSelectedSearchResult,
      clickSearchResult,
      className,
    } = this.props;

    return (
      <div className={classNames('searchbar', className)} ref={this.searchBar}>
        <form
          action="/food/search"
          method="GET"
          autoComplete="off"
          className="searchbar__search-form"
          onSubmit={
            onSubmit
              ? e => {
                  e.preventDefault();
                  updateSearchTerm(displaySearchTerm);
                  onSubmit(displaySearchTerm);
                  clearSearchResults();
                  return false;
                }
              : this.submitForm
          }
          ref={this.searchForm}
        >
          <div
            className={classNames({
              'searchbar__input-container': true,
              'showing-results': searchResults.length > 0,
            })}
          >
            <SearchBarInput
              onChange={term => {
                updateSearchTerm(term);
                const finalGetSuggested = customGetSuggested || getSuggestedSearchResults;
                finalGetSuggested(term);
              }}
              onKeyDown={this.handleKeyDown}
              value={displaySearchTerm}
            />

            <input
              aria-label="Search BBC Food's recipes"
              className="searchbar__search-button"
              id="searchbar-search-button"
              name="searchButton"
              type="submit"
              value=""
            />
            <button
              className="searchbar__close-button"
              onClick={clearSearchTerm}
              onKeyDown={event => (event.key === 'Enter' ? clearSearchTerm() : {})}
              type="button"
            />
          </div>
        </form>
        {searchResults.length > 0 && (
          <SearchBarResults
            searchResults={searchResults}
            searchTerm={searchTerm}
            selectedSearchResultIndex={selectedSearchResultIndex}
            clickSearchResult={
              onSubmit
                ? result => {
                    updateSearchTerm(result);
                    onSubmit(result);
                    clearSearchResults();
                    return false;
                  }
                : clickSearchResult
            }
            selectSearchResult={selectSearchResult}
            clearSelectedSearchResult={clearSelectedSearchResult}
            clearSearchResults={clearSearchResults}
          />
        )}
      </div>
    );
  }
}

SearchBar.propTypes = {
  customGetSuggested: PropTypes.func,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  clearSearchTerm: PropTypes.func.isRequired,
  shouldSubmit: PropTypes.bool.isRequired,
  searchResults: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, title: PropTypes.string })
  ).isRequired,
  searchTerm: PropTypes.string.isRequired,
  displaySearchTerm: PropTypes.string.isRequired,
  selectedSearchResultIndex: PropTypes.number,
  getSuggestedSearchResults: PropTypes.func.isRequired,
  selectSearchResult: PropTypes.func.isRequired,
  clearSearchResults: PropTypes.func.isRequired,
  clearSelectedSearchResult: PropTypes.func.isRequired,
  clickSearchResult: PropTypes.func.isRequired,
  updateSearchTerm: PropTypes.func.isRequired,
  updateDisplaySearchTerm: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {
  customGetSuggested: undefined,
  className: undefined,
  onSubmit: undefined,
  selectedSearchResultIndex: undefined,
};

export default connect(
  state => ({
    shouldSubmit: state.searchBarReducer.shouldSubmit,
    searchResults: state.searchBarReducer.searchResults,
    searchTerm: state.searchBarReducer.searchTerm,
    displaySearchTerm: state.searchBarReducer.displaySearchTerm,
    selectedSearchResultIndex: state.searchBarReducer.selectedSearchResultIndex,
    activePageId: state.pageReducer.activePageId,
  }),
  {
    clearSearchTerm: clearSearchTermAction,
    selectSearchResult: selectSearchResultAction,
    clearSearchResults: clearSearchResultsAction,
    clearSelectedSearchResult: clearSelectedSearchResultAction,
    clickSearchResult: clickSearchResultAction,
    getSuggestedSearchResults: getSuggestedSearchResultsAction,
    updateSearchTerm: updateSearchTermAction,
    updateDisplaySearchTerm: updateDisplaySearchTermAction,
  }
)(onClickOutside(SearchBar));
