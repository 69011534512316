import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

if (process.env.CLIENT) {
  require('./SearchBarInput.scss'); // eslint-disable-line global-require
}

export const getPlaceholderText = (width = 1024) => {
  if (width >= 600 && width < 900) {
    return 'Discover recipes on BBC Food';
  } else if (width >= 900) {
    return 'Discover more than 10,000 fantastic recipes on BBC Food';
  }
  return 'Discover recipes';
};

const SearchBarInput = ({ onChange, onKeyDown, value }) => {
  const [placeholder, setPlaceholder] = useState();

  useEffect(() => {
    const handleResize = () => {
      setPlaceholder(getPlaceholderText(window.innerWidth || 1024));
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <input
      className="searchbar__input"
      name="q"
      onChange={event => onChange(event.target.value)}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      type="text"
      value={value}
    />
  );
};

SearchBarInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default SearchBarInput;
