import {
  CLEAR_SEARCH_RESULTS,
  UPDATE_SEARCH_RESULTS,
  UPDATE_SEARCH_TERM,
  UPDATE_DISPLAY_SEARCH_TERM,
  SELECT_SEARCH_RESULT,
  CLEAR_SELECTED_SEARCH_RESULT,
} from './constants';

let foods;
export const getSuggestedSearchResults =
  (term = '') =>
  async dispatch => {
    if (term.length === 0) {
      dispatch({ type: CLEAR_SEARCH_RESULTS });
      return;
    }

    if (!foods) {
      const request = await fetch('/food/api/search/suggestions');
      if (request.status !== 200) return;
      // eslint-disable-next-line
      foods = (await request.json()).foods;
    }

    const lowercaseTerm = term.toLowerCase();

    const searchResults = foods
      .filter(({ title }) => title.toLowerCase().includes(lowercaseTerm))
      .sort((a, b) => (b.title.startsWith(lowercaseTerm) ? 1 : -1))
      .slice(0, 5)
      .map(({ id, title }) => ({ id, title }));

    dispatch({ type: UPDATE_SEARCH_RESULTS, searchResults });
  };

export const updateDisplaySearchTerm = searchTerm => ({
  type: UPDATE_DISPLAY_SEARCH_TERM,
  searchTerm,
});

export const updateSearchTerm =
  (searchTerm, shouldSubmit = false) =>
  async dispatch => {
    dispatch(updateDisplaySearchTerm(searchTerm));
    dispatch({ type: UPDATE_SEARCH_TERM, searchTerm, shouldSubmit });
  };

export const selectSearchResult = index => ({
  type: SELECT_SEARCH_RESULT,
  index,
});

export const clearSearchResults = () => ({
  type: CLEAR_SEARCH_RESULTS,
});

export const clearSelectedSearchResult = () => ({
  type: CLEAR_SELECTED_SEARCH_RESULT,
});

export const clearSearchTerm = () => async dispatch => {
  dispatch(updateSearchTerm(''));
  dispatch(clearSearchResults());
};

export const clickSearchResult = result => async dispatch =>
  dispatch(updateSearchTerm(result, true));
